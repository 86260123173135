import React from "react"

import { PhotoPlaceholder } from "react-placeholder-image"
import {
  GiDragonBalls,
  GiMagicHat,
  GiStoneThrone,
  GiAncientSword,
  GiHealing,
  GiTiedScroll,
} from "react-icons/gi"
import { FaChess } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="grid-1-2 has-mb-5">
      <div
        className="has-padding-1 has-bg-light align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon" style={{ color: "rebeccapurple" }}>
          <FaChess />
        </span>
        <h2 className="has-txt-bold has-mb-5">Strategy</h2>{" "}
        <p>
          Encapsulating the essence of a brand is essential, it is at the core
          of every step you take, driven by meticulous planning and a deep
          understanding of the space around us. We help you position and
          differentiate your brand in the vast sea of peers and competitors.
          From sketchbooks to the real world, we give you the road ahead.
        </p>
      </div>

      <div
        className="has-padding-1 has-bg-light align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon" style={{ color: "rebeccapurple" }}>
          <GiMagicHat />
        </span>
        <h2 className="has-txt-bold has-mb-5">Branding</h2>{" "}
        <p>
          Brands are all about user driven experiences, evolving and adapting to
          the constantly changing digital space. Branding for us is breathing
          life into ideas scribbled in your notebook somewhere. The true power
          of a brand lies with the user and we tap into that power to drive your
          brand to success.
        </p>
      </div>
    </div>
    <div className="grid-1-2 has-mb-5">
      <div
        className="has-padding-1 has-bg-light align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon" style={{ color: "rebeccapurple" }}>
          <GiAncientSword />
        </span>
        <h2 className="has-txt-bold has-mb-5">Experience Design</h2>{" "}
        <p>
          Spaces both digital and physical are filled with nuances that turn
          them into memorable experiences. We design and deliver experience
          driven solutions that enable you to engage consumers and bring them
          back for more. Every interaction matters, every click counts. We
          enable your products to deliver experiences that ensure positive
          impact.
        </p>
      </div>

      <div
        className="has-padding-1 has-bg-light align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon" style={{ color: "rebeccapurple" }}>
          <GiHealing />
        </span>
        <h2 className="has-txt-bold has-mb-5">Integrated Marketing</h2>{" "}
        <p>
          Building holistic campaigns and executing them to the word is our
          niche. Covering every touchpoint and establishing your brand in the
          phygital space is what we are all about. From digital marketing to
          outdoor advertising we work closely with you to surpass the goals you
          set for your brand.
        </p>
      </div>
    </div>

    <div className="grid-1-2 has-mb-5">
      <div
        className="has-padding-1 has-bg-light align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon" style={{ color: "rebeccapurple" }}>
          <GiTiedScroll />
        </span>
        <h2 className="has-txt-bold has-mb-5">Content Services</h2>{" "}
        <p>
          Great content resonates with the soul of a brand. We create content
          channels you need to establish your brand’s presence and drive it
          deeper into your audience. Starting the conversation about your brand
          and engaging the world it interacts with is what we execute to
          perfection..
        </p>
      </div>

      <div
        className="has-padding-1 has-bg-light align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon" style={{ color: "rebeccapurple" }}>
          <GiDragonBalls />
        </span>
        <h2 className="has-txt-bold has-mb-5">Social Media services</h2>{" "}
        <p>
          Tap into the limitless potential of social media platforms empowering
          your brand to reach a global audience and convert users into
          consumers. We help brands strategize and execute custom built social
          media plans, enabling them to beautifully and effectively represent
          their brand on every social media channel out there. Let’s go social
          together!
        </p>
      </div>
    </div>
  </Layout>
)

export default ServicesPage
